interface IGoogleTagManagerEventTypes {
  destination: string;
  origin: string;
  itineraries: string;
  date: string;
  hotelAvailabilities: string;
  flightAvailabilities: string;
  dpAvailabilities: string;
  lineAd: string;
  consultation: string;
  flightSelection: string;
  flightPreReservation: string;
  dpPreReservation: string;
}

export const GoogleTagManagerEventTypes: IGoogleTagManagerEventTypes = {
  destination: "destination",
  origin: "origin",
  itineraries: "itineraries",
  date: "date",
  hotelAvailabilities: "hotel_availabilities",
  flightAvailabilities: "flight_availabilities",
  dpAvailabilities: "dp_availabilities",
  lineAd: "line_ad",
  consultation: "consultation",
  flightSelection: "flight_selection",
  flightPreReservation: "flight_pre_reservation",
  dpPreReservation: "dp_pre_reservation",
};
