import { GoogleTagManagerEventTypes } from "../constants/GoogleTagManagerEventTypes";
import { FlightTypes } from "../constants/FlightTypes";

export const event = (action, param) => {
  window.dataLayer.push({
    event: action,
    ...param,
  });
};

export const hotelAvailabilitiesEvent = (cacheId, params) => {
  const adults = params.guests.reduce(
    (acc, cur) => acc + Number(cur.number_of_adult),
    0
  );
  const agesOfChildren = params.guests.reduce((acc, cur) => {
    if (cur.ages_of_children && cur.ages_of_children.length > 0) {
      return acc.concat(cur.ages_of_children);
    }
    return acc;
  }, []);
  event(GoogleTagManagerEventTypes.hotelAvailabilities, {
    destination_id: params.destination_id,
    destination_type: params.destination_type,
    destination: params.destination,
    start_date: params.checkin_date,
    end_date: params.checkout_date,
    adults: adults,
    ages_of_children: agesOfChildren,
    cache_id: cacheId,
  });
};

export const flightAvailabilitiesEvent = (cacheId, params) => {
  const adults = params.traveler.number_of_adult;
  const agesOfChildren = params.traveler.ages_of_children || [];
  let startDate = params.departure_date;
  let endDate = params.return_date;
  if (params.flight_type === FlightTypes.multiCity) {
    startDate = params.itineraries?.[0].departure_date;
    if (params.itineraries?.length > 0) {
      endDate =
        params.itineraries[params.itineraries.length - 1].departure_date;
    }
  }
  event(GoogleTagManagerEventTypes.flightAvailabilities, {
    destination_id: params.destination_id,
    destination_type: params.destination_type,
    destination: params.destination,
    origin_id: params.origin_id,
    origin_type: params.origin_type,
    origin: params.origin,
    start_date: startDate,
    end_date: endDate,
    itineraries: params.itineraries,
    flight_type: params.flight_type,
    cabin_class: params.cabin_class,
    adults: adults,
    ages_of_children: agesOfChildren,
    cache_id: cacheId,
  });
};

export const dpAvailabilitiesEvent = (cacheId, params) => {
  const adults = params.guests.reduce(
    (acc, cur) => acc + Number(cur.number_of_adult),
    0
  );
  const agesOfChildren = params.guests.reduce((acc, cur) => {
    if (cur.ages_of_children && cur.ages_of_children.length > 0) {
      return acc.concat(cur.ages_of_children);
    }
    return acc;
  }, []);
  const startDate = params.departure_date;
  const endDate = params.return_date;
  event(GoogleTagManagerEventTypes.dpAvailabilities, {
    destination_id: params.destination_id,
    destination_type: params.destination_type,
    destination: params.destination,
    origin_id: params.origin_id,
    origin_type: params.origin_type,
    origin: params.origin,
    start_date: startDate,
    end_date: endDate,
    flight_type: params.flight_type,
    cabin_class: params.cabin_class,
    adults: adults,
    ages_of_children: agesOfChildren,
    cache_id: cacheId,
  });
};

export const lineAdEvent = () => {
  event(GoogleTagManagerEventTypes.lineAd, {});
};

export const consultationEvent = () => {
  event(GoogleTagManagerEventTypes.consultation, {});
};

export const flightSelectionEvent = () => {
  event(GoogleTagManagerEventTypes.flightSelection, {});
};

export const flightPreReservationEvent = () => {
  event(GoogleTagManagerEventTypes.flightPreReservation, {});
};

export const dpPreReservationEvent = () => {
  event(GoogleTagManagerEventTypes.dpPreReservation, {});
};
